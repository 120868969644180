import React, { useEffect, useContext, useState } from "react";
import Context from "context/Context";
import WebpayButton from "./PaymentMethodsButtons/WebpayButton";
import MercadopagoButton from "./PaymentMethodsButtons/MercadopagoButton";
import RedsysButton from "./PaymentMethodsButtons/RedsysButton";
import WiretransferButton from "./PaymentMethodsButtons/WiretransferButton";
import styles from "assets/jss/views/eventPageSections/paymentTabStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Button,
} from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import DiscountCoupon from "./DiscountCoupon";

const useStyles = makeStyles(styles);

const PaymentList = (props) => {
  const {
    paymentMethodList,
    paymentMethod,
    handlePaymentMethod,
    setPaymentMethod,
    eventId,
    tickets,
  } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });
  const [open, setOpen] = useState(false);
  const [couponEnabled, setCouponEnabled] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const contextType = useContext(Context);
  const { t, getDiscount, setDiscount } = contextType;

  const paymentList = {
    webpay: (
      <WebpayButton
        paymentMethod={paymentMethod}
        handlePaymentMethod={handlePaymentMethod}
      />
    ),
    mercadopago: (
      <MercadopagoButton
        paymentMethod={paymentMethod}
        handlePaymentMethod={handlePaymentMethod}
      />
    ),
    redsys: (
      <RedsysButton
        paymentMethod={paymentMethod}
        handlePaymentMethod={handlePaymentMethod}
      />
    ),
    transferencia: (
      <WiretransferButton
        paymentMethod={paymentMethod}
        handlePaymentMethod={handlePaymentMethod}
      />
    ),
  };

  useEffect(() => {
    setPaymentMethod(paymentMethodList[0].code);
  }, [paymentMethodList]);

  useEffect(() => {
    getDiscount()?.code !== undefined
      ? setCouponEnabled(getDiscount().code)
      : setCouponEnabled("");
  }, [getDiscount()]);

  return (
    <Box>
      <Grid container justify="left">
        <Grid xs={12} style={{ textAlign: "left" }}>
          <div style={{display:"flex"}}>
            <Typography className={classes.couponSection}>
              Código de descuento: {couponEnabled !== ""
                ? `${couponEnabled}`
                : ""}
            </Typography>
            <Typography className={classes.couponDiscountPercentage}>
              {couponEnabled !== ""
                ? `${getDiscount().variation*100}%`
                : ""}
            </Typography>
          </div>
          <Typography className={classes.couponLink} disabled={couponEnabled} onClick={() => handleOpen()}>
            Ingresar un código de descuento
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid xs={12} style={{ textAlign: "center"}}>
          <Typography classes={{ root: classes.paymentMessage }}>
            {t("please_select_method")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={isMobile ? 2 : 0}>
        {paymentMethodList.map((method, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={12}
            className={classes.itemFullCenter}
          >
            {paymentList[method.code]}
          </Grid>
        ))}
      </Grid>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          className: classes.containerModal,
        }}
      >
        <Fade in={open}>
          <DiscountCoupon
            handleClose={handleClose}
            eventId={eventId}
            setDiscount={setDiscount}
            tickets={tickets}
          />
        </Fade>
      </Modal>
    </Box>
  );
};

export default PaymentList;
