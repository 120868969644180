import React, { useEffect } from 'react'

const Redsys = (props) => {
  const { paymentResponse } = props
  const { redsys_signature, redsys_merchant_parameters } = paymentResponse

  useEffect(() => {
    if (redsys_signature !== '') {
      document.getElementById('formPayment').submit()
    }
  }, [redsys_signature])

  return (
    <div>
      <form name="from" action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST" id='formPayment'>
        <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
        <input type="hidden" name="Ds_MerchantParameters" value={redsys_merchant_parameters}/>
        <input type="hidden" name="Ds_Signature" value={redsys_signature}/>  
      </form>
    </div>
  )
}

export default Redsys
