import React, { Fragment, useState, useEffect, useContext } from "react";
import Context from "../../context/Context";
import { useMediaQuery } from "react-responsive";

import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Container,
  MenuItem,
  createMuiTheme,
} from "@material-ui/core";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import orange from "@material-ui/core/colors/orange";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/components/activePrivateEventsStyles.js";
import { createPrivateEventFormSchema } from "../../utils/validations.js";

import DateFnsUtils from "@date-io/date-fns";
import { Field, ErrorMessage, useFormik, FormikProvider } from "formik";
import { useSnackbar } from "notistack";

import es from "date-fns/locale/es";
import moment from "moment";
import { getCounties } from "provider/publicProvider/provider.js";

import {
  postCreatePrivateEvent,
  postCreatePrivateEventImage,
  postCreatePrivateEventExcel,
} from "provider/privateProvider/provider";

const useStyles = makeStyles(styles);

const CreatePrivateEventForm = (props) => {
  const contextType = useContext(Context);
  const { getCountry, t } = contextType;
  const { setActiveTab } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [paradeArray, setParadeArray] = useState([]);
  const [inputArrayId, setInputArrayId] = useState(0);
  const [inputArrayValues, setInputArrayValues] = useState([]);
  const [counties, setCounties] = useState([{ name: "", id: "" }]);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState();
  const [eventImgName, setEventImgName] = useState("");
  const [eventGuestsName, setEventGuestsName] = useState("");

  let [image, setImage] = useState();
  let [excel, setExcel] = useState();

  let eventImg = new FormData();
  let eventGuests = new FormData();

  const handleImg = (event) => {
    setImage(event.target.files[0]);
    setEventImgName(event.target.files[0].name);
  };

  const handleExcel = (event) => {
    setExcel(event.target.files[0]);
    setEventGuestsName(event.target.files[0].name);
  };

  useEffect(() => {
    getCounties()
      .then((response) => {
        setCounties(response);
        setCountry(getCountry());
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleNotification = (message, variant) => {
    enqueueSnackbar(message, {
      action,
      autoHideDuration: 5000,
      variant: variant,
    });
  };

  const formik = useFormik({
    initialValues: {
      eventName: "",
      placeName: "",
      placeAddress: "",
      countyId: "",
      startDate: new Date(),
      startHour: new Date(),
      endDate: "",
      endHour: new Date(),
      invitationMessage: "",
      inputArrayValues,
    },
    validationSchema: createPrivateEventFormSchema,
    onSubmit: (values) => {
      if (eventImgName !== "" && eventGuestsName !== "") {
        verificatorParadeArray(inputArrayValues[0])
          .then(() => {
            formatterValuesToDispatch({
              url: country,
              event: {
                name: values.eventName,
                startDate: `${moment(values.startDate).format(
                  "YYYY/MM/DD"
                )} ${moment(values.startHour).format("HH:mm")}`,
                endDate: `${moment(values.startDate).format(
                  "YYYY/MM/DD"
                )} ${moment(values.endHour).format("HH:mm")}`,
                location: values.placeName,
                address: values.placeAddress,
                additionalStops: values.inputArrayValues,
                countyId: values.countyId,
                image: values.imgEvent,
                invitation: values.invitationMessage,
              },
            });
            // alert(JSON.stringify(values.inputArrayValues, null, 2))
          })
          .catch(() => {
            handleNotification(
              "Asegurate de llenar todos los campos de Paradas Adicionales",
              "error"
            );
          });
      } else {
        eventImgName === "" &&
          handleNotification(t("create_private_event_image_error"), "error");
        eventGuestsName === "" &&
          handleNotification(
            t("create_private_event_guest_list_error"),
            "error"
          );
      }
    },
  });

  const verificatorParadeArray = (array) => {
    return new Promise((resolve, reject) => {
      array.map((parade) => {
        if (parade.address === "" || parade.countyId === "") {
          reject();
        }
      });
      resolve();
    });
  };

  const formatterValuesToDispatch = (values) => {
    postCreatePrivateEvent(values)
      .then(({ id }) => {
        eventImg.append("image", image);
        postCreatePrivateEventImage(id, eventImg);
        eventGuests.append("guests", excel);
        postCreatePrivateEventExcel(id, eventGuests);
        handleNotification(t("create_event_success"), "success");
        setActiveTab(0);
      })
      .catch((error) => {
        console.error("error create_private_form", error);
        handleNotification(t("create_event_failed"), "error");
      });
  };

  const addParade = () => {
    setInputArrayId(inputArrayId + 1);
    const newInputArray = [...paradeArray];
    newInputArray.push({
      _id: inputArrayId,
      address: "",
      countyId: "",
      time: new Date(),
    });
    setParadeArray(newInputArray);
  };

  const deleteParade = (id) => {
    setParadeArray(paradeArray.filter((item) => item._id !== id));
  };

  const changeValuesParade = (id, inputValue, field) => {
    const newparadeArray = paradeArray.map((i) => {
      if (id === i._id) {
        if (field === 1) {
          i.address = inputValue;
        } else if (field === 2) {
          i.countyId = inputValue;
        } else if (field === 3) {
          i.time = inputValue;
        }
      }
      return i;
    });

    setParadeArray(newparadeArray);
  };

  const extractArrayValues = () => {
    inputArrayValues[0] = paradeArray
      .map(
        (item) =>
          item.value !== "" && {
            address: item.address,
            countyId: item.countyId,
            time: moment(item.time).format("HH:mm"),
          }
      )
      .filter((item) => item !== false);
    formik.values.inputArrayValues = inputArrayValues[0];
  };

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  const getDateSelected = (_id) => {
    return paradeArray.map((item) => {
      if (item._id === _id) {
        return item.time;
      }
    });
  };

  const action = (key) => {
    return (
      <Fragment>
        <Button
          className={classes.dismissNotification}
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </Button>
      </Fragment>
    );
  };

  if (loading) {
    return (
      <Container>
        <ThemeProvider theme={defaultMaterialTheme}>
          <Grid
            item
            xs={12}
            md={12}
            classes={classes.boxCircularProgress}
            style={{
              height: "500px",
              alignItems: "center",
              paddingTop: "40%",
              paddingLeft: "50%",
            }}
          >
            <CircularProgress />
          </Grid>
        </ThemeProvider>
      </Container>
    );
  }

  return (
    <div>
      <span className={classes.title}>Tus Eventos</span>
      <Grid container xs={12} className={!isMobile && classes.formContainer}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container xs={12}>
              <span className={classes.titleForm}>
                {t("create_private_event_title")}
              </span>
            </Grid>
            <Grid container xs={12}>
              <Grid container xs={12} md={12}>
                <Grid item xs={12}>
                  {t("create_private_event_name")}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="eventName"
                    placeholder={t("create_private_event_name_form")}
                    onChange={formik.handleChange}
                    value={formik.values.eventName}
                    variant="outlined"
                    fullWidth
                    className={classes.field}
                    helperText={<ErrorMessage name="eventName" />}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} md={12}>
              <Grid container xs={12} md={12}>
                <span className={classes.titleForm}>
                  {t("create_private_event_date")}
                </span>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12}>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        label={t("create_private_event_date_start")}
                        inputVariant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullwidth
                        value={formik.values.startDate}
                        selected={formik.values.startDate}
                        className={classes.timeAndDate}
                        onChange={(event) =>
                          formik.setFieldValue(
                            "startDate",
                            moment(event).format("YYYY/MM/DD")
                          )
                        }
                        helperText={<ErrorMessage name="startDate" />}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12}>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                      <TimePicker
                        format="HH:mm"
                        name="startHour"
                        label={t("create_private_event_date_start_hour")}
                        inputVariant="outlined"
                        fullwidth
                        value={formik.values.startHour}
                        selected={formik.values.startHour}
                        className={classes.field}
                        onChange={(event) =>
                          formik.setFieldValue("startHour", event)
                        }
                        helperText={<ErrorMessage name="startHour" />}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12}>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                      <TimePicker
                        format="HH:mm"
                        label={t("create_private_event_date_end_hour")}
                        inputVariant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={formik.values.endHour}
                        selected={formik.values.endHour}
                        className={classes.field}
                        onChange={(event) =>
                          formik.setFieldValue("endHour", event)
                        }
                        helperText={<ErrorMessage name="endHour" />}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid container xs={12} md={12}>
                <span className={classes.titleForm}>
                  {t("create_private_event_place")}
                </span>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12}>
                  {t("create_private_event_place_name")}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="placeName"
                    placeholder={t("create_private_event_place_name_form")}
                    onChange={formik.handleChange}
                    value={formik.values.placeName}
                    variant="outlined"
                    fullWidth
                    className={classes.field}
                    helperText={<ErrorMessage name="placeName" />}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12}>
                  {t("create_private_event_address")}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="placeAddress"
                    placeholder="Av ..."
                    onChange={formik.handleChange}
                    value={formik.values.placeAddress}
                    variant="outlined"
                    fullWidth
                    className={classes.field}
                    helperText={<ErrorMessage name="placeAddress" />}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12} md={12}>
                  {process.env.REACT_APP_TRANSLATE_DISTRICT}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Field
                    as={TextField}
                    name={"countyId"}
                    placeholder={process.env.REACT_APP_TRANSLATE_DISTRICT}
                    onChange={formik.handleChange}
                    value={formik.values.countyId}
                    variant="outlined"
                    fullWidth
                    select
                    className={classes.field}
                    helperText={<ErrorMessage name="countyId" />}
                  >
                    {counties.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid container xs={12} md={12}>
                {paradeArray.length > 0 && (
                  <Grid item xs={12} className={classes.titleForm}>
                    {t("create_private_event_add_stop_parade")}
                  </Grid>
                )}
                {paradeArray.length > 0 &&
                  paradeArray.map(({ _id, value }) => (
                    <Grid container xs={12} style={{ alignItems: "center" }}>
                      <Grid container xs={12} md={4}>
                        <Grid item xs={12} md={12}>
                          {t("create_private_event_add_stop_parade_address")}
                        </Grid>
                        <Grid container xs={12} md={12}>
                          <Field
                            as={TextField}
                            name={"address" + _id}
                            placeholder="Av..."
                            onChange={(event) =>
                              changeValuesParade(_id, event.target.value, 1)
                            }
                            value={value}
                            variant="outlined"
                            fullWidth
                            className={classes.field}
                          />
                        </Grid>
                      </Grid>
                      <Grid container xs={12} md={4}>
                        <Grid item xs={12} md={12}>
                          {process.env.REACT_APP_TRANSLATE_DISTRICT}
                        </Grid>
                        <Grid container xs={12} md={12}>
                          <Field
                            as={TextField}
                            name={"parade" + _id}
                            placeholder={process.env.REACT_APP_TRANSLATE_DISTRICT}
                            onChange={(event) =>
                              changeValuesParade(_id, event.target.value, 2)
                            }
                            value={value}
                            variant="outlined"
                            fullWidth
                            select
                            className={classes.field}
                          >
                            {counties.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} md={3}>
                        <Grid item xs={12} md={12}>
                          {t("create_private_event_add_stop_parade_hour")}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={es}
                            >
                              <TimePicker
                                name={"parade" + _id}
                                format="HH:mm"
                                inputVariant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={getDateSelected(_id)}
                                selected={getDateSelected(_id)}
                                onChange={(event) =>
                                  changeValuesParade(_id, event, 3)
                                }
                                helperText={<ErrorMessage name="paradeDate" />}
                              />
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} md={1} className={classes.AI}>
                        <Grid
                          container
                          xs={12}
                          md={12}
                          style={{ paddingTop: 30, paddingLeft: 10 }}
                        >
                          <p
                            className={classes.deleteParadeButton}
                            onClick={() => deleteParade(_id)}
                          >
                            -
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ padding: 10, textAlign: "center" }}
                >
                  <p
                    className={classes.addParadeText}
                    onClick={() => addParade()}
                  >
                    + {t("create_private_event_add_stop_parade")}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid container xs={12} md={12}>
                <span className={classes.titleForm}>
                  {t("create_private_event_image")}
                </span>
              </Grid>
              <Grid item xs={3} style={{ alignItems: "bottom" }}>
                <label htmlFor="imgEvent" style={{ alignItems: "bottom" }}>
                  <input
                    id="imgEvent"
                    name="imgEvent"
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleImg}
                    accept="image/*"
                  />
                  <Button
                    color="secondary"
                    component="span"
                    className={classes.uploadFileButton}
                  >
                    {t("create_private_event_image_search")}
                  </Button>
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                style={{ alignItems: "center", paddingTop: 10 }}
              >
                <p>
                  {eventImgName === ""
                    ? t("create_private_event_image_no_selected")
                    : eventImgName}
                </p>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid container xs={12} md={12}>
                <span className={classes.titleForm}>
                  {t("create_private_event_invitation")}
                </span>
              </Grid>
              <Grid item xs={12}>
                {t("create_private_event_invitation_text")}
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="invitationMessage"
                  placeholder={t("create_private_event_invitation_text_form")}
                  variant="outlined"
                  className={classes.field}
                  onChange={formik.handleChange}
                  value={formik.values.invitationMessage}
                  fullWidth
                  multiline
                  rows={7}
                  rowsMax={8}
                  helperText={<ErrorMessage name="invitationMessage" />}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} md={12}>
              <Grid item xs={12} md={12}>
                <span
                  className={classes.titleForm}
                  style={{ padding: "10px 0px" }}
                >
                  {t("create_private_event_guest_list")}
                </span>
              </Grid>

              <Grid item xs={12} md={12}>
                <p>
                  {t("create_private_event_guest_list_description")}
                </p>
              </Grid>

              <Grid
                container
                xs={12}
                md={6}
                style={{ alignItems: "center", paddingTop: 10 }}
              >
                <Grid item xs={12} md={5}>
                  <Button
                    href={process.env.REACT_APP_API_URL+"/files/invitados.xlsx"}
                    download
                    className={classes.downloadXlsxButton}
                  >
                    <GetAppIcon />{" "}
                    {t("create_private_event_guest_list_format_file")}
                  </Button>
                </Grid>

                <Grid item xs={12} md={7} style={{ paddingTop: 10 }}>
                  <p> {t("create_private_event_guest_list_download_file")}</p>
                </Grid>
              </Grid>

              <Grid
                container
                xs={12}
                md={6}
                style={{ alignItems: "center", paddingTop: 10 }}
              >
                <Grid item xs={12} md={5}>
                  <label htmlFor="guestList">
                    <input
                      id="guestList"
                      name="guestList"
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleExcel}
                      accept=".xlsx,.xls"
                    />
                    <Button
                      color="secondary"
                      component="span"
                      className={classes.uploadFileButton}
                    >
                      {t("create_private_event_guest_list_upload_file")}
                    </Button>
                  </label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  style={{ alignItems: "center", paddingTop: 10 }}
                >
                  <p>
                    {eventGuestsName === ""
                      ? t("create_private_event_image_no_selected")
                      : eventGuestsName}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid container md={12} style={{ textAlign: "center" }}>
              <Grid item xs={12} md={4} style={{ padding: 10 }}>
                <Button
                  className={classes.createEventButton}
                  onClick={() => extractArrayValues()}
                  type="submit"
                >
                  {t("create_private_event_send_form")}
                </Button>
              </Grid>
              <Grid item xs={12} md={4} style={{ padding: 10 }}>
                {/* <Button
                  className={classes.saveDataButton}
                  onClick={() => extractArrayValues()}
                >
                  {t("create_private_event_save_form")}
                </Button> */}
              </Grid>
              <Grid item xs={12} md={4} style={{ padding: 10 }}>
                <Button
                  className={classes.cancelEventButton}
                  onClick={() => setActiveTab(0)}
                >
                  {t("create_private_event_cancel")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </Grid>
    </div>
  );
};

export default CreatePrivateEventForm;
