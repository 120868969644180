// List petitions for apiProvider
export const GET_CHANNELS = 'GET_CHANNELS'
export const GET_COUNTIES = 'GET_COUNTIES'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID'
export const GET_EVENT_LIST = 'GET_EVENT_LIST'
export const GET_GROUPS = 'GET_GROUPS'
export const GET_LANDING_COMMENTS = 'GET_LANDING_COMMENTS'
export const GET_LANDING_SLIDES = 'GET_LANDING_SLIDES'
export const GET_LOCALES = 'GET_LOCALES'
export const GET_PLACES = 'GET_PLACES'
export const GET_RECOMMENDED_EVENT_LIST = 'GET_RECOMMENDED_EVENT_LIST'
export const GET_TOKEN = 'GET_TOKEN'

// List Path for apiProvider
export const CHANNELS_PATH = 'api/channels'
export const COUNTIES = 'counties'
export const COUNTRIES_PATH = 'api/countries'
export const COUNTY_CODE = 'countyCode'
export const EMPTY_PATH = ''
export const EVENTS = 'events'
export const EVENTS_PATH = 'api/events'
export const GROUPS = 'groups'
export const GROUPS_PATH = 'groups'
export const LANDING_COMMENTS_PATH = 'landing-comments'
export const LANDING_SLIDES_PATH = 'landing-slides'
export const LOCALES = 'locales'
export const PLACES_PATH = 'api/places'
export const RECOMMENDED_EVENTS = 'recommended-events'
export const RECOMMENDED_TRANSFERS = 'api/recommended-events'
export const TICKETS = 'tickets'
export const TICKETS_PATH = 'tickets'
export const TOKEN_PATH = 'api/oauth/v2/token'

// List petitions for authProvider
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_SIGNUP = 'AUTH_SIGNUP'
export const GET_PERFIL = 'GET_PERFIL'
export const UPDATE_PERFIL = 'UPDATE_PERFIL'
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT'

// List Path for authProvider
export const LOGIN_PATH = 'shop-api/login'
export const SIGNUP_PATH = 'shop-api/register'
export const USER_PATH = 'shop-api/me'
export const VERIFY_PATH = 'shop-api/verify-account'

// Type of events
export const FEATURED_EVENT = 'FEATURED_EVENT'
export const NORMAL_EVENT = 'NORMAL_EVENT'

// Country
export const SET_COUNTRY = 'SET_COUNTRY'

// Translate
export const SET_TRANSLATE = 'SET_TRANSLATE'
export const SET_LANGUAGE_CHANGED = 'SET_LANGUAGE_CHANGED'

// Places
export const SET_PLACES = 'SET_PLACES'

// Counter Input
export const SET_QUANTITY = 'SET_QUANTITY'

// User Data
export const SET_USER_DATA = 'SET_USER_DATA'

// Commune Selected in Event
export const SET_COMMUNE = 'SET_COMMUNE'

// Address Inputs of detail
export const SET_ADDRESS_INPUTS = 'SET_ADDRESS_INPUTS'

// Passenger Inputs of detail
export const SET_PASSENGER_INPUTS = 'SET_PASSENGER_INPUTS'

// Routes passenger inputs
export const SET_ROUTES_PASSENGER_INPUTS = 'SET_ROUTES_PASSENGER_INPUTS'

// Countries
export const SET_COUNTRIES = 'SET_COUNTRIES'

// Locales
export const SET_LOCALES = 'SET_LOCALES'

// Button Step Ticket
export const SET_BUTTON_STEP_TICKET = 'SET_BUTTON_STEP_TICKET'

// Clear general states
export const SET_CLEAR_CONTEXT = 'SET_CLEAR_CONTEXT'

// Set Discount of a coupon
export const SET_DISCOUNT = 'SET_DISCOUNT'

// Internationalization
export const BR = 'BR'
export const CL = 'CL'
export const EN = 'EN'
export const ES = 'ES'
export const PE = 'PE'

export const PT_BR = 'pt_BR'
export const ES_CL = 'es_CL'
export const EN_US = 'en_US'
export const ES_ES = 'es_ES'
export const ES_PE = 'es_PE'

// Languages
export const LANGUAGES = [BR, CL, EN, ES, PE]

// Log in / Sign up
export const LOG_IN = 'LOG_IN'
export const SIGN_UP = 'SIGN_UP'

// Url
export const CURRENT_URL = window.location.href
export const DUMMY_URL_BR = 'https://www.vmove.br'
export const DUMMY_URL_CL = 'vmove.agua.devaid.cl'
export const DUMMY_URL_EN = 'https://www.vmove.en'
export const DUMMY_URL_ES = 'https://www.vmove.es'
export const LOCALE_URL = 'http://localhost:3000/'

// Payment
export const SET_PAYMENT = 'SET_PAYMENT'
export const GET_PAYMENT_METHODS_LIST = 'GET_PAYMENT_METHODS_LIST'

//Province
export const PROVINCE_BY_EVENT = 'PROVINCE_BY_EVENT'
export const GET_EVENT_CATEGORY_BY_ID = 'GET_EVENT_CATEGORY_BY_ID'

//Log status
export const SET_LOG_STATUS = 'SET_LOG_STATUS'

//Purchase history
export const GET_PURCHASE_HISTORY = 'payment-history'

//Search
export const SET_SEARCH = 'SET_SEARCH'
